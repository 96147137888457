module.exports = {
  defaultTitle: 'Zubego GmbH',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Zubego GmbH',
  url: 'https://zubego.de',
  legalName: 'Zubego GmbH',
  defaultDescription: 'Zubego GmbH - Beteiligungsgesellschaft',
  socialLinks: {
    twitter: 'http://www.twitter.com/zubairov',
    github: 'https://github.com/zubairov',
    linkedin: 'https://www.linkedin.com/in/zubairov/',
    instagram: 'https://instagram.com/zubairov'
  },
  googleAnalyticsID: 'G-4D8D5TS8KM',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    twitter: '@zubairov',
  },
  address: {
    city: 'Bonn',
    region: 'NRW',
    country: 'Germany',
    zipCode: '53173',
  },
  contact: {
    email: 'info@zubego.de',
    phone: 'TBD',
  },
  foundingDate: '2021',
};
